import { proxy } from 'valtio';
export const _logicDrawer = (config) => {
    const logicStore = proxy({
        opened: false,
        onClose: () => {
            logicStore.opened = false;
        },
        size: 336,
        ...config.initialProps,
    });
    return logicStore;
};
