import { Select } from '@mantine/core';
import { useSnapshot } from 'valtio';
import { getSelectProps } from '~/pages/strategy_center_admin/_utils/getSelectProps';
import { pageConfig } from '~/pages/strategy_center_admin/pageConfig';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export const PeriodSelect = () => {
    const period = useSnapshot(pageStore.strategyOverviewStore.filter).period;
    return (<Select {...getSelectProps({
        value: period,
        data: pageConfig.strategyPerformancePeriodOptions,
        onChange: datum => {
            pageStore.strategyOverviewStore.filter.period = datum.value;
        },
    })}/>);
};
