import { proxy } from 'valtio';
export const _logicString = (config) => {
    const logicStore = proxy({
        value: config?.initialState ?? '',
        setValue: (value) => {
            logicStore.value = value;
        },
        clear: () => {
            logicStore.value = '';
        },
        reset: () => {
            logicStore.value = config?.initialState ?? '';
        },
        onChange: (event) => {
            logicStore.value = event.target.value;
        },
    });
    return logicStore;
};
