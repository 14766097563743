import { proxy } from 'valtio';
export const _logicOneOfObjects = (options) => {
    const initialValue = options.find(option => option.asInitialState === true);
    const template = proxy({
        options: options,
        active: initialValue || options[0],
        setActive: (option) => {
            template.active = option;
        },
    });
    return template;
};
