import { proxy } from 'valtio';
export const _logicBoolean = (config) => {
    const logicStore = proxy({
        value: config?.initialState ?? false,
        setValue: (value) => {
            logicStore.value = value;
        },
        reset: () => {
            logicStore.value = config?.initialState ?? false;
        },
        onChange: (event) => {
            logicStore.value = event.target.checked;
        },
    });
    return logicStore;
};
