import { Select } from '@mantine/core';
import { useSnapshot } from 'valtio';
import { apirc } from '~/configs/apirc';
import { createApi } from '~/configs/apirc/OrderPlacementService';
import { StrategyDevAPI } from '~/configs/apirc/StrategyDevAPI';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
import { component } from '~/utils/component';
export const ServiceAlternativeSwitch = component((props) => {
    const serviceUrls = props.apiType === 'strategy'
        ? useSnapshot(pageStore.me).strategyServiceUrl
        : useSnapshot(pageStore.me).orderServiceUrl;
    return (<Select label={props.label} placeholder='請選擇' value={serviceUrls || ''} onChange={serviceUrl => {
            if (props.apiType === 'strategy') {
                pageStore.me.strategyServiceUrl = serviceUrl;
                apirc.strategy = new StrategyDevAPI({
                    baseUrl: serviceUrl || `__NOT_SET_DOMAIN__`,
                    middlewares: StrategyDevAPI.middlewares,
                });
            }
            else if (props.apiType === 'order') {
                pageStore.me.orderServiceUrl = serviceUrl;
                apirc.orderPlacementService = createApi({
                    baseUrl: serviceUrl || `http://__NOT_SET_DOMAIN__`,
                });
            }
        }} data={props.dataList}/>);
});
