import { dayjs } from '@futures.tw/dayjs';
import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
export function createSettlementStore() {
    const state = {
        settlementRules: [],
        async reloadSettlementRules() {
            apirc.strategy
                .http('get', '/api/contractSettlementRules')
                .request({
                contractMonth: store.filter.contractMonth,
                'datetime[gte]': store.filter.datetimeGte.format(),
                'datetime[lte]': store.filter.datetimeLte.format(),
                symbolRoot: store.filter.symbolRoot,
            })
                .then(res => {
                store.settlementRules = res.data || [];
            });
        },
        filter: {
            symbolRoot: '',
            contractMonth: '',
            datetimeGte: dayjs().subtract(7, 'day'),
            datetimeLte: dayjs().add(7, 'day'),
        },
    };
    const store = proxy({
        ...state,
    });
    return store;
}
