import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
export function createOrderServiceStore() {
    const initialState = {
        strategies: [],
        subscriptions: [],
        clientOrderSettings: null,
    };
    const actions = {
        async fetchStrategiesSubscriptions(strategyId) {
            apirc.orderPlacementService
                .GET('/api/strategies/{strategyId}/subscriptions', {
                params: {
                    path: {
                        strategyId,
                    },
                },
            })
                .then(res => {
                store.subscriptions = res.data || [];
            });
        },
    };
    const store = proxy({
        ...initialState,
        ...actions,
    });
    return store;
}
