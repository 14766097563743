import styled from '@emotion/styled';
import { LongShortRatio } from '~/pages/strategy_center_admin/strategy-overview/_components/LongShortRatio';
import { PeriodSelect } from '~/pages/strategy_center_admin/strategy-overview/_components/PeriodSelect';
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss';
export const StrategyOverviewControlBar = () => {
    return (<classes.root>
      <classes.position.body>
        <classes.position.item>即時部位狀態</classes.position.item>
        <LongShortRatio />
      </classes.position.body>

      <classes.timeRanges.body>
        <classes.timeRanges.title>績效採計區間</classes.timeRanges.title>
        <PeriodSelect />
      </classes.timeRanges.body>
    </classes.root>);
};
const classes = {
    root: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
  `,
    container: styled.div ``,
    manager: {
        title: styled.div ``,
        button: styled.div ``,
    },
    position: {
        body: styled.div `
      ${fill_horizontal_cross_center};
      width: auto;
      gap: 4px;
    `,
        title: styled.div `
      width: 100px;
    `,
        item: styled.div ``,
    },
    timeRanges: {
        body: styled.div `
      ${fill_horizontal_cross_center};
      width: auto;
      padding: 0 4px;
      gap: 4px;
    `,
        title: styled.div `
      width: 100px;
    `,
    },
    //這邊是豬豬原始提供的部分
    section: styled.div ``,
    title: styled.div ``,
};
StrategyOverviewControlBar.classes = classes;
