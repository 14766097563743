import { isString } from 'lodash';
/**
 * @example
 *   //
 *   return (
 *     <Select
 *       label='幣別'
 *       {...getSelectProps({
 *         value: form.values.currency,
 *         data: pageConfig.currencyOptions,
 *         onChange: changed => {
 *           form.setValues({ currency: changed.value })
 *         },
 *       })}
 *       data={pageConfig.currencyOptions}
 *     />
 *   )
 */
export const getSelectProps = (config) => {
    return {
        value: config.value,
        data: config.data,
        onChange: value => {
            const found = config.data.find(datum => {
                if (isString(datum)) {
                    return datum === value;
                }
                return datum.value === value;
            });
            if (found) {
                config.onChange?.(found);
            }
        },
    };
};
