import { toastRequestFailed } from '~/pages/strategy_center_admin/_utils/toastRequestFailed';
import { toastRequestSuccess } from '~/pages/strategy_center_admin/_utils/toastRequestSuccess';
export function createResponseToast() {
    return {
        async onResponse(options) {
            const method = options.request.method.toLowerCase();
            const showToastOn = ['post', 'patch', 'delete', 'put'];
            if (!showToastOn.includes(method))
                return;
            if (options.response.ok) {
                toastRequestSuccess(options.request.url);
            }
            else if (!options.response.ok) {
                const err = await options.response.json();
                toastRequestFailed(new Error(`[${options.response.status}]: ` + err?.detail || '後端 API 系統回應未知錯誤'));
            }
        },
    };
}
