import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
export function createUsersStore() {
    const actions = {
        async loadUsers() {
            const users = await apirc.strategy.http('get', '/api/users').request({});
            store.users = users;
            return users;
        },
        async deleteUser(account) {
            await apirc.strategy.http('delete', '/api/users/{account}').request({ account });
        },
    };
    const state = {
        users: [],
    };
    const store = proxy({
        ...state,
        ...actions,
    });
    return store;
}
