import { createContext, useContext } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
export const _createValtioContext = (initialState) => {
    const context = createContext(undefined);
    const Provider = props => {
        const state = FrLogic.useLogic(() => {
            return proxy({
                ...initialState,
                ...props.interface,
            });
        });
        return <context.Provider value={state}>{props.children}</context.Provider>;
    };
    const useCtx = () => useProxy(useContext(context));
    return {
        context,
        Provider,
        useCtx,
    };
};
