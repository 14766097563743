import { IconChartHistogram, IconHeartRateMonitor, IconNotes, IconSitemap, IconUsers, } from '@tabler/icons-react';
import { AgentProduct } from '~/configs/AgentProduct';
import { calcLongShortRatio } from '~/pages/strategy_center_admin/_utils/calcLongShortRatio';
var Href;
(function (Href) {
    /** 商品 */
    Href["instrument"] = "/strategy_center_admin/instrument";
    /** 商品結算 */
    Href["instrumentSettlement"] = "/strategy_center_admin/instrument/settlement";
    /** 商品轉倉 */
    Href["instrumentRoll"] = "/strategy_center_admin/instrument/roll";
    /** 上傳機 */
    Href["monitor"] = "/strategy_center_admin/monitor";
    /** 策略對帳單 */
    Href["strategyStatements"] = "/strategy_center_admin/strategy?id={id}";
    /** 策略總覽 */
    Href["strategyOverview"] = "/strategy_center_admin/strategy-overview";
    /** 使用者列表 */
    Href["user"] = "/strategy_center_admin/user";
    /** 事件日誌 */
    Href["eventLog"] = "/strategy_center_admin/system/event-log";
    /** 稽核日誌 */
    Href["auditLog"] = "/strategy_center_admin/system/audit-log";
    /**
     * 虛擬交易
     *
     * 這個是以前的 virtual-exchange 即 https://exchange.futures-ai.com/
     */
    Href["orderPanel"] = "/strategy_center_admin/order-panel";
    /** 下單中台策略列表 */
    Href["orderServerStrategies"] = "/strategy_center_admin/order-service/strategies";
    /** 下單中台客戶訂閱啟用列表 */
    Href["orderServerStrategiessubscriptions"] = "/strategy_center_admin/order-service/strategies/subscriptions?id={id}";
    /** 下單中台系統配置 */
    Href["orderServerClientOrderSettings"] = "/strategy_center_admin/order-service/settings";
})(Href || (Href = {}));
/** @readonly 唯讀，靜態組態 */
export const pageConfig = {
    Href,
    validations: {
        settlementContractMonth: {
            input: /^\d{6}(W[1,2,4,5])?$/,
        },
        currentContractMonth: (value) => {
            const toValid = /^\d{6}$/;
            const hasValidText = toValid.test(value || '');
            const isEmpty = !value;
            if (isEmpty) {
                return null;
            }
            if (!hasValidText) {
                return '合約格式不正確';
            }
        },
    },
    /** 側邊欄 */
    sidebarItems: [
        {
            label: '策略',
            icon: IconChartHistogram,
            initiallyOpened: true,
            links: [
                { label: '策略總覽', link: Href.strategyOverview },
                {
                    label: '交易面板',
                    link: Href.orderPanel,
                    functionHidden: [AgentProduct['kgi@strategy_center_admin']],
                    functionVisibleByRole: ['Admin', 'Strategist'],
                },
            ],
        },
        {
            label: '商品',
            icon: IconNotes,
            initiallyOpened: true,
            links: [
                {
                    label: '商品列表',
                    link: Href.instrument,
                    functionVisibleByRole: ['Admin', 'Strategist', 'ReadOnlyAdmin'],
                },
                {
                    label: '結算規則',
                    link: Href.instrumentSettlement,
                    functionVisibleByRole: ['Admin', 'Strategist', 'ReadOnlyAdmin'],
                },
                {
                    label: '轉倉規則',
                    link: Href.instrumentRoll,
                    functionVisibleByRole: ['Admin', 'Strategist', 'ReadOnlyAdmin'],
                },
            ],
        },
        {
            label: '上傳機',
            icon: IconHeartRateMonitor,
            initiallyOpened: true,
            links: [
                {
                    label: '上傳機列表',
                    link: Href.monitor,
                    functionVisibleByRole: ['Admin', 'Strategist', 'ReadOnlyAdmin'],
                },
            ],
        },
        {
            label: '使用者',
            icon: IconUsers,
            initiallyOpened: true,
            links: [{ label: '使用者列表', link: Href.user }],
        },
        {
            label: '下單中台',
            icon: IconSitemap,
            initiallyOpened: true,
            links: [
                {
                    label: '策略狀態',
                    link: Href.orderServerStrategies,
                    functionHidden: [AgentProduct['fubon@strategy_center_admin']],
                },
                {
                    label: '設定',
                    link: Href.orderServerClientOrderSettings,
                    functionHidden: [AgentProduct['fubon@strategy_center_admin']],
                },
            ],
        },
        {
            label: '系統',
            icon: IconSitemap,
            initiallyOpened: true,
            links: [
                {
                    label: '事件日誌',
                    link: Href.eventLog,
                    functionVisibleByRole: ['Admin', 'ReadOnlyAdmin'],
                },
                {
                    label: '稽核日誌',
                    link: Href.auditLog,
                    functionVisibleByRole: ['Admin', 'ReadOnlyAdmin'],
                },
            ],
        },
    ],
    /** 多空比公式 */
    calcLongShortRatio,
    /** K棒選項 */
    barTypeOptions: [
        { value: 'Seconds', label: '秒' },
        { value: 'Minutes', label: '分' },
        { value: 'Hours', label: '時' },
        { value: 'Days', label: '日' },
    ],
    adminRoleOptions: [
        { value: 'Admin', label: 'Admin' },
        { value: 'Salesperson', label: 'Salesperson' },
        { value: 'Strategist', label: 'Strategist' },
        { value: 'ReadOnlyAdmin', label: 'ReadOnlyAdmin' },
        { value: 'StrategyViewer', label: 'StrategyViewer' },
    ],
    /** 貨幣選項 */
    currencyOptions: [
        { value: 'TWD', label: 'TWD' },
        { value: 'USD', label: 'USD' },
        { value: 'JPY', label: 'JPY' },
        { value: 'CNY', label: 'CNY' },
        { value: 'HKD', label: 'HKD' },
    ],
    /** 策略績效採計週期區間 */
    strategyPerformancePeriodOptions: [
        { value: 'All', label: '全部' },
        { value: 'OneMonth', label: '一個月' },
        { value: 'ThreeMonths', label: '三個月' },
        { value: 'SixMonths', label: '六個月' },
        { value: 'OneYear', label: '一年' },
        { value: 'TwoYears', label: '兩年' },
        { value: 'ThreeYears', label: '三年' },
        { value: 'YearToDate', label: '年初至今' },
        { value: 'Simulated', label: '模擬' },
        { value: 'Online', label: '真實' },
    ],
    /** 事件等級 */
    eventLevelOptions: [
        { label: '全部', value: '' },
        { label: '除錯', value: 'Debug' },
        { label: '資訊', value: 'Info' },
        { label: '警告', value: 'Warning' },
        { label: '錯誤', value: 'Error' },
        { label: '嚴重', value: 'Fatal' },
    ],
    /** 事件名稱 */
    eventNameOptions: [
        { label: '全部', value: '' },
        { label: 'CalculatePortfolio', value: 'CalculatePortfolio' },
        { label: 'CalculatePerformanceOverviews', value: 'CalculatePerformanceOverviews' },
        { label: 'ChangePosition', value: 'ChangePosition' },
        { label: 'ContractSettlement', value: 'ContractSettlement' },
        { label: 'SignalrConnect', value: 'SignalrConnect' },
        { label: 'SignalrDisconnect', value: 'SignalrDisconnect' },
        { label: 'ArchivedPositionChangeLog', value: 'ArchivedPositionChangeLog' },
        { label: 'ContractRoll', value: 'ContractRoll' },
        { label: 'SignalrReconnect', value: 'SignalrReconnect' },
        { label: 'Unknown', value: 'Unknown' },
    ],
};
