import { Modal } from '@mantine/core';
import { useEffect } from 'react';
import { proxy, ref } from 'valtio';
import { useProxy } from 'valtio/utils';
export function createModalStore() {
    const store = proxy({
        _isOpen: false,
        _content: null,
        /** 用於提示是否需要事先安裝 Container 組件 */
        _isModalContainerMounted: false,
        /**
         * @example
         *   //
         *   pageStore.modalStore.showModalComponent(function RenderComponent(props) {
         *     return <Alert color='red'>意外錯誤</Alert>
         *   })
         */
        showModalComponent: (componentType) => {
            if (store._isModalContainerMounted === false) {
                console.error(`請先配置 <modalStore.ModalContainer /> 到布局頁面裡`);
            }
            store._content = componentType;
            store._isOpen = true;
        },
        closeModal: () => {
            store._isOpen = false;
            store._content = null;
        },
        onCloseModal: () => {
            store.closeModal();
        },
        /**
         * @example
         *   //
         *   export const Layout = () => {
         *     const modal = useProxy(pageStore.modalStore)
         *
         *     return (
         *       <div>
         *         <Header1 />
         *         <Sidebar1 />
         *         <Col1 />
         *         <modal.ModalContainer /> // ✅
         *       </div>
         *     )
         *   }
         */
        ModalContainer: ref(function ModalContainer() {
            const state = useProxy(store);
            useEffect(() => {
                store._isModalContainerMounted = true;
                return () => {
                    store._isModalContainerMounted = false;
                };
            }, []);
            return (<Modal opened={state._isOpen} onClose={state.onCloseModal}>
          {state._content && <state._content />}
        </Modal>);
        }),
    });
    return store;
}
