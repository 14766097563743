import { proxy, ref } from 'valtio';
import { component } from '~/utils/component';
import { _Icon } from '~/modules/FrLogic/v1/logics/Sorter/_Icon';
import { createValtioStateGetter } from '~/utils/createValtioStateGetter';
export const _logicSorter = (sortableColumns) => {
    const template = proxy({
        /** 可用於排序的欄位們 */
        columns: sortableColumns,
        /** 正在按照欄位排序 */
        by: [
            {
                by: sortableColumns.at(0) || '',
                order: 'desc',
            },
        ],
        /**
         * `httpParams` 方便你塞入其它地方，例如 useSWR({...})
         *
         * @example
         *   //
         *   apirc.strategies.useSWR({
         *     simulated: true,
         *     ...pageStore.sorting.httpParams, // { sortBy: 'price', sortOrder: 'desc' },
         *   })
         */
        get httpParams() {
            return createValtioStateGetter(template, snap => {
                const by = snap.by[0];
                return {
                    sortBy: by.by,
                    sortOrder: by.order,
                };
            });
        },
        /** 設定排序欄位 */
        setSortId: (id) => {
            const sameId = template.by[0].by === id;
            const descReversed = template.by[0].order === 'asc' ? 'desc' : 'asc';
            if (sameId) {
                // 只要反轉 asc | desc 即可
                template.by[0].order = descReversed;
            }
            else {
                // 改變 id 並預設回到 desc
                template.by[0] = {
                    by: id,
                    order: 'desc',
                };
            }
        },
        Icon: ref(component(props => {
            return (<_Icon {...props} logic={template}>
            {props.children}
          </_Icon>);
        })),
    });
    return template;
};
