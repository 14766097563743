import { css } from '@emotion/react';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import { component } from '~/utils/component';
export const _Icon = component((props) => {
    const sortable = props.logic.columns.includes(props.by);
    const isColumnSorting = props.logic.by[0].by === props.by;
    const isAsc = props.logic.by[0].order === 'desc';
    let icon;
    if (isColumnSorting && isAsc) {
        icon = <IconArrowDown size={20}/>;
    }
    if (isColumnSorting && !isAsc) {
        icon = <IconArrowUp size={20}/>;
    }
    return (<div onClick={() => {
            if (!sortable) {
                console.warn(`無法 props.by 因為 !sortable 請檢查 props.logic.columns 配置可用的 sortBy 欄位`, {
                    'props.by': props.by,
                    'props.logic.columns': props.logic.columns,
                    sortable,
                });
                return;
            }
            props.logic.setSortId(props.by);
        }} css={[
            sortable &&
                css `
              cursor: pointer;
            `,
            css `
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            gap: 4px;
          `,
        ]}>
        <div>{props.children}</div>
        <div>{icon}</div>
      </div>);
});
