import { _useLogic } from '~/modules/FrLogic/v1/hooks/_useLogic';
import { _logicBoolean } from '~/modules/FrLogic/v1/logics/_logicBoolean';
import { _logicColorSchema } from '~/modules/FrLogic/v1/logics/_logicColorSchema';
import { _logicDrawer } from '~/modules/FrLogic/v1/logics/_logicDrawer';
import { _logicFilter } from '~/modules/FrLogic/v1/logics/_logicFilter';
import { _logicOneOfObjects } from '~/modules/FrLogic/v1/logics/_logicOneOfObjects';
import { _logicOneOfStrings } from '~/modules/FrLogic/v1/logics/_logicOneOfStrings';
import { _logicPagination } from '~/modules/FrLogic/v1/logics/_logicPagination';
import { _logicSorter } from '~/modules/FrLogic/v1/logics/_logicSorter';
import { _logicString } from '~/modules/FrLogic/v1/logics/_logicText';
import { _logicLocalStorage } from '~/modules/FrLogic/v1/logics/_logicLocalStorage';
import { _createValtioContext } from '~/modules/FrLogic/v1/utils/_createValtioContext';
/**
 * # valtioStore 邏輯結構生成器
 *
 * 用於生成一個「preset 預定義好的 Valtio 邏輯結構(state, methods...etc)模版」
 *
 * ## 這解決了什麼問題？
 *
 * !! 生成出 N 個邏輯結構
 *
 * 生成出某些客製化組件必要的邏輯結構，讓開發者拿現成的邏輯來客製自己的組件
 *
 * ## 這不解決什麼問題？
 *
 * 不提供內建、不提供開箱即用展示組件
 *
 * !! 因為職責不在於「展示」，而在於「復用邏輯結構」
 *
 * @example
 *   //
 *   // 先假設你有一套 PageLevel 專用的 ValtioStore
 *   const pageStore = proxy({
 *     foo: 'foo',
 *     bar: 'bar',
 *
 *     //
 *     // 現在你需要再加一個 tabs
 *     // 你發現這套結構已經有模版可用
 *     // 直接套用模版馬上有以下邏輯和狀態
 *     // `options: string[]`
 *     // `active: boolean`
 *     // `setActive()`
 *     tabDisplay: FrLogic.oneOfString(['選擇權分析', '選擇權報價', '選擇權訊號']),
 *
 *     // 生成第二格不一樣的語義，但一樣的邏輯結構
 *     radioSelected: FrLogic.oneOfString(['全選', '台指期', '小那']),
 *   })
 */
export const FrLogic = {
    createContext: _createValtioContext,
    /**
     * useLogic(loginCreator) 可使 「該邏輯結構」 作為獨立的 「component state」
     *
     * @example
     *   //
     *   // state 只會獨立存活在 TestComponent 內，不會和其它組件產生交互影響
     *   function TestComponent(props: { onChange?: AnyFunction }) {
     *     const pagination = useProxy(FrLogic.useLogic(() => FrLogic.pagination()))
     *
     *     pagination.page === 1
     *     pagination.goto(10)
     *     pagination.page === 10
     *     pagination.goNext()
     *     pagination.page === 11
     *
     *     return (
     *       <div
     *         onClick={() => {
     *           pagination.goNext()
     *           props.onChange?.(pagination.page)
     *         }}
     *       >
     *         {pagination.page}
     *       </div>
     *     )
     *   }
     */
    useLogic: _useLogic,
    /**
     * @example
     *   //
     *   const pageStore = proxy({
     *     pagination: FrLogic.pagination({
     *       initialPageAt: 17,
     *       pageSize: 200,
     *     }),
     *   })
     */
    pagination: _logicPagination,
    string: _logicString,
    boolean: _logicBoolean,
    /**
     * 後端 restAPI 排序參數規範生成
     *
     * @example
     *   //
     *   // 通常用於排序 Data[] 邏輯
     *   type ResponseJsonDatum = {
     *     profit1M: number
     *     profit3M: number
     *     profit1Y: number
     *   }
     *
     *   const pageStore = proxy({
     *     sorting: FrLogic.sortBy<ResponseJsonDatum>(['profit1M', 'profit3M', 'profit1Y']),
     *   })
     *
     *   pageStore.sorting.by // { by: 'profit3M', order: 'desc' }
     *   pageStore.sorting.setSortId('profit3M')
     *   pageStore.sorting.columns // ['profit1M', 'profit3M', 'profit1Y']]
     *   pageStore.sorting.httpParams // { sortBy: 'profit1M', sortOrder: 'desc' }
     */
    sortBy: _logicSorter,
    /**
     * 後端 restAPI 篩選參數規範生成
     *
     * @example
     *   //
     *   const pageStore = proxy({
     *     filter: FrLogic.filter([
     *       'simulated',
     *       'symbol',
     *       'price',
     *       'profit1M',
     *       'profit3M',
     *       'profit1Y',
     *       'datetime',
     *     ]),
     *   })
     *
     *   pageStore.filter.setValue('simulated', true)
     *   pageStore.filter.setValue('symbol', 'TX')
     *   pageStore.filter.setRange0('profit1M', 3.3)
     *   pageStore.filter.setRange1('profit1Y', 16.78)
     *   pageStore.filter.setRange('price', 15000, 18000)
     *   pageStore.filter.httpParams // { 'price[gte]': 15000, 'price[lte]': 18000, 'profit1M[gte]': 3.3, ...etc  }
     *
     *   apirc.overviews.useSWR({ ...pageStore.filter.httpParams })
     */
    filterBy: _logicFilter,
    /**
     * @example
     *   //
     *   // 使用情境
     *   const pageStore = proxy({
     *     tabDisplay: FrLogic.oneOfStrings(['選擇權分析', '選擇權報價', '選擇權訊號']),
     *     radioSelected: FrLogic.oneOfStrings(['全選', '台指期', '小那']),
     *   })
     *
     *   pageStore.tabs.active // '選擇權分析'
     *   pageStore.tabs.setActive('選擇權訊號')
     *   pageStore.tabs.options // ['選擇權分析', '選擇權報價', '選擇權訊號']
     */
    oneOfStrings: _logicOneOfStrings,
    /**
     * @example
     *   //
     *   // 使用情境
     *   const pageStore = proxy({
     *     filterBy: FrLogic.oneOfObjects([
     *       {
     *         displayName: '小那',
     *         value: 'NQ-1',
     *       },
     *       {
     *         displayName: '小那',
     *         value: 'NQ-1',
     *       },
     *       {
     *         displayName: '台指期',
     *         value: 'TX-1',
     *       },
     *     ]),
     *   })
     *
     *   pageStore.tabs.active // { displayName, value }
     *   pageStore.tabs.setActive({ displayName, value })
     *   pageStore.tabs.options // [{ displayName, value }, { displayName, value }, ...]
     */
    oneOfObjects: _logicOneOfObjects,
    /**
     * @example
     *   //
     *   //
     *   export const pageStore = proxy({
     *     ctx: {
     *       jwt: FrLogic.localStorage({ key: 'jwt', initialState: 'I_am_init' }),
     *     },
     *   })
     *
     *   pageStore.setValue('jwtValue#fjiahsf83385y8gyaighf')
     *   pageStore.value // 'jwtValue#fjiahsf83385y8gyaighf'
     *   pageStore.reset()
     *   pageStore.value // 'I_am_init'
     */
    localStorage: _logicLocalStorage,
    /**
     * 產生 mantineUI:`<Drawer {...state} />` 的 state
     *
     * @example
     *   //
     *   export const pageStore = proxy({
     *     ctx: {
     *       drawer1: FrLogic.drawer({ initialProps: { position: 'left' } }),
     *     },
     *   })
     */
    drawer: _logicDrawer,
    colorSchema: _logicColorSchema,
};
