import { useEffect, useState } from 'react';
import { proxy, subscribe } from 'valtio';
import { _logicLocalStorage } from '~/modules/FrLogic/v1/logics/_logicLocalStorage';
export const _logicColorSchema = () => {
    const _keyStore = _logicLocalStorage({ key: 'colorSchema', initialState: 'light' });
    const store = proxy({
        useThemeKey: () => {
            const [themeKey, setThemeKey] = useState(() => (_keyStore.value || 'light'));
            useEffect(() => {
                return subscribe(_keyStore, () => {
                    if (['dark', 'light'].includes(_keyStore.value)) {
                        setThemeKey(_keyStore.value);
                    }
                });
            }, []);
            return themeKey;
        },
        asLight: () => {
            _keyStore.setValue('light');
        },
        asDark: () => {
            _keyStore.setValue('dark');
        },
        toggle: () => {
            _keyStore.value === 'light' || !_keyStore.value ? store.asDark() : store.asLight();
        },
    });
    return store;
};
