import { proxy } from 'valtio';
import { devtools, watch } from 'valtio/utils';
import { AgentProduct } from '~/configs/AgentProduct';
import { apirc } from '~/configs/apirc';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import { createResponseToast } from '~/pages/strategy_center_admin/_middlewares/createResponseToast';
import { createAuditLogStore } from '~/pages/strategy_center_admin/_store/createAuditLogStore';
import { createEventLogStore } from '~/pages/strategy_center_admin/_store/createEventLogStore';
import { createInstrumentStore } from '~/pages/strategy_center_admin/_store/createInstrumentStore';
import { createMeStore } from '~/pages/strategy_center_admin/_store/createMeStore';
import { createModalStore } from '~/pages/strategy_center_admin/_store/createModalStore';
import { createMonitorStore } from '~/pages/strategy_center_admin/_store/createMonitorStore';
import { createOrderPanelStore } from '~/pages/strategy_center_admin/_store/createOrderPanelStore';
import { createOrderServiceStore } from '~/pages/strategy_center_admin/_store/createOrderServiceStore';
import { createRollStore } from '~/pages/strategy_center_admin/_store/createRollStore';
import { createSettlementStore } from '~/pages/strategy_center_admin/_store/createSettlementStore';
import { createStrategistStore } from '~/pages/strategy_center_admin/_store/createStrategistStore';
import { createStrategyOverviewStore } from '~/pages/strategy_center_admin/_store/createStrategyOverviewStore';
import { createStrategyStore } from '~/pages/strategy_center_admin/_store/createStrategyStore';
import { createUsersStore } from '~/pages/strategy_center_admin/_store/createUsersStore';
export const pageStore = proxy({
    drawer1: FrLogic.drawer({ initialProps: { position: 'left' } }),
    me: createMeStore(),
    modalStore: createModalStore(),
    /** 如果你一層 modal 不夠，須要跳出第二層的話 */
    modal2Store: createModalStore(),
    strategistsStore: createStrategistStore(),
    strategyOverviewStore: createStrategyOverviewStore(),
    instrumentsStore: createInstrumentStore(),
    strategyStore: createStrategyStore(),
    monitorStore: createMonitorStore(),
    usersStore: createUsersStore(),
    eventLog: createEventLogStore(),
    auditLog: createAuditLogStore(),
    settlementStore: createSettlementStore(),
    rollStore: createRollStore(),
    orderPanel: createOrderPanelStore(),
    orderService: createOrderServiceStore(),
});
devtools(pageStore, { name: AgentProduct['futuresai@strategy_center_admin'] });
/**
 * 這裡有兩個不同的 API resource
 *
 * - orderPlacementService
 * - strategy
 *
 * 然而 orderPlacementService 會需要使用 strategy 的 jwt 作為 headers
 *
 * 這裡會自動同步 jwt 到 orderPlacementService
 */
watch(get => {
    const jwt = get(pageStore.me).jwt;
    const jwtMiddleware = {
        onRequest: config => {
            if (jwt) {
                config.request.headers.set('Authorization', `Bearer ${jwt}`);
            }
            else {
                config.request.headers.set('Authorization', `__NO_JWT__`);
            }
        },
    };
    apirc.orderPlacementService.use(jwtMiddleware);
    return () => {
        apirc.orderPlacementService.eject(jwtMiddleware);
    };
});
//
// 其餘的 middlewares
apirc.orderPlacementService.use(createResponseToast());
