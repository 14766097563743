import { proxy } from 'valtio';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__';
export const _logicLocalStorage = (config) => {
    const key = `${fr_agents.agentProduct}.${config.key}`;
    const logicStore = proxy({
        /** 用於 valtio 提供 useProxy, subscribe 能夠偵測變化 */
        _value: '',
        get value() {
            return __IS_CLIENT__ ? localStorage.getItem(key) || '' : '';
        },
        /** 包含了 namespace 的 key 的值，可直接用在 `localStorage.getItem($key)` */
        get localStorageKey() {
            return key;
        },
        setValue: (value) => {
            logicStore._value = value;
            localStorage.setItem(key, logicStore._value);
        },
        /** 復原到程式一開始給的 initialValue */
        resetValue: () => {
            logicStore._value = config?.initialState ?? '';
            localStorage.setItem(key, logicStore._value);
        },
        /** 移除所有字元，變成空字串 */
        removeValue: () => {
            logicStore._value = '';
            localStorage.removeItem(key);
        },
    });
    return logicStore;
};
