import { proxy } from 'valtio';
import { createValtioStateGetter } from '~/utils/createValtioStateGetter';
export const _logicPagination = (initial) => {
    const paginationState = {
        page: initial?.page || 1,
        pageSize: initial?.pageSize || 100,
        totalPages: 1,
        totalCount: 1,
    };
    const getters = {
        getMantineProps() {
            return createValtioStateGetter(logicStore, snap => ({
                total: snap.totalPages,
                disabled: snap.totalPages <= 1,
                withEdges: true,
                value: snap.page,
                ...initial?.customMantineProps,
                onChange: page => {
                    logicStore.page = page;
                    initial?.customMantineProps?.onChange?.(page);
                },
            }));
        },
        getHttpParams() {
            return createValtioStateGetter(logicStore, snap => {
                return {
                    page: snap.page,
                    pageSize: snap.pageSize,
                };
            });
        },
    };
    const actions = {
        setPagination: (options) => {
            if (options.page)
                logicStore.page = options.page;
            if (options.pageSize)
                logicStore.pageSize = options.pageSize;
            logicStore.totalPages = options.totalPages ?? logicStore.totalPages;
            logicStore.totalCount = options.totalCount ?? logicStore.totalCount;
        },
        goto: (page) => {
            logicStore.page = page;
        },
        goNext: () => {
            logicStore.page += 1;
        },
        goPrev: () => {
            logicStore.page -= 1;
        },
        goFirst: () => {
            logicStore.page = 1;
        },
        goLast: () => {
            logicStore.page = logicStore.totalPages;
        },
    };
    const logicStore = proxy({
        ...paginationState,
        ...actions,
        ...getters,
    });
    return logicStore;
};
