import styled from '@emotion/styled';
import { filter } from 'lodash';
import { useMemo } from 'react';
import { useProxy } from 'valtio/utils';
import { fill_horizontal_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { calcLongShortRatio } from '~/pages/strategy_center_admin/_utils/calcLongShortRatio';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
/** 多空比 */
export const LongShortRatio = () => {
    const overviews = useProxy(pageStore.strategyOverviewStore).overviews;
    const longShortRatio = useMemo(() => {
        const flat = filter(overviews, pos => pos.currentPosition === 0).length;
        const long = filter(overviews, pos => pos.currentPosition && pos.currentPosition > 0).length;
        const short = filter(overviews, pos => pos.currentPosition && pos.currentPosition < 0).length;
        return {
            flat,
            long,
            short,
            ratio: calcLongShortRatio({
                flatCount: flat,
                longCount: long,
                shortCount: short,
            }),
        };
    }, [overviews]);
    return (<classes.container>
      <classes.position.long>多單 {longShortRatio.long}</classes.position.long>
      <classes.position.short>空單 {longShortRatio.short}</classes.position.short>
      <classes.position.empty>無部位 {longShortRatio.flat}</classes.position.empty>
      <classes.position.ratio>
        多空比 {(longShortRatio.ratio * 100).toFixed(2)}%
      </classes.position.ratio>
    </classes.container>);
};
const classes = {
    container: styled.div `
    display: grid;
    grid-template-columns: 20% 20% 30% 30%;
    font-weight: 500;
    & > * {
      ${fill_horizontal_all_center};
      min-width: 80px;
    }
  `,
    position: {
        long: styled.div `
      color: #cc0000;
    `,
        short: styled.div `
      color: #00aa00;
    `,
        empty: styled.div `
      color: #252525;
    `,
        ratio: styled.div ``,
    },
};
