import { isDayjs } from 'dayjs';
import { isArray, isNil, reduce } from 'lodash';
import { proxy } from 'valtio';
import { createValtioStateGetter } from '~/utils/createValtioStateGetter';
export const _logicFilter = (paramKeys) => {
    const state = proxy({
        /** 可配置篩選的欄位，預設都是「無篩選」 */
        value: paramKeys.reduce((options$, paramsKey) => {
            return {
                ...options$,
                [paramsKey]: undefined,
            };
        }, {}),
        /**
         * `httpParams` 方便你塞入其它地方，例如 useSWR({...})
         *
         * @example
         *   //
         *   apirc.strategies.useSWR({
         *     simulated: true,
         *     // { price: '15000..18000', profit1Y: '..16.78', symbol: 'TX' },
         *     ...pageStore.filter.httpParams,
         *   })
         */
        get httpParams() {
            return createValtioStateGetter(state, () => {
                return reduce(state.value, (httpParams$, valueMapp, __key) => {
                    const key$ = __key;
                    const httpParamsFragment = {};
                    valueMapp?.forEach(([value, preset$]) => {
                        let value$string;
                        if (isNil(value)) {
                            value$string = undefined;
                        }
                        else if (isDayjs(value)) {
                            value$string = value.format();
                        }
                        else if (isArray(value)) {
                            value$string = value.join(',');
                        }
                        else {
                            value$string = String(value);
                        }
                        if (!preset$) {
                            httpParamsFragment[key$] = value$string;
                        }
                        else if (preset$) {
                            httpParamsFragment[`${key$}[${preset$}]`] = value$string;
                        }
                    });
                    return {
                        ...httpParams$,
                        ...httpParamsFragment,
                    };
                }, {});
            });
        },
        /**
         * @example
         *   //
         *   .setValue('symbol', 'TX') // { symbol: 'TX' }
         */
        setValue: (paramKey, value) => {
            state.value[paramKey] = [[value, null]];
        },
        /**
         * @example
         *   //
         *   .setRange0('price', 15000) // { price: '15000..' }
         */
        setRange0: (paramKey, value) => {
            state.value[paramKey] = [[value, 'gte']];
        },
        /**
         * @example
         *   //
         *   .setRange1('price', 23000) // { price: '..23000' }
         */
        setRange1: (paramKey, value) => {
            state.value[paramKey] = [[value, 'lte']];
        },
        /**
         * @example
         *   //
         *   .setRange('price', 15000, 23000) // { price: '15000..23000' }
         */
        setRange: (paramKey, value0, value1) => {
            state.value[paramKey] = [
                [value0, 'gte'],
                [value1, 'lte'],
            ];
        },
        /**
         * @example
         *   //
         *   .setRange1('price', 23000) // { price: '..23000' }
         *   .remove('price') // { price: undefined }
         */
        remove: (paramKey) => {
            state.value[paramKey] = undefined;
        },
        /**
         * @example
         *   //
         *   .removeAll() // { price: undefined, ... all props be undefined as removed }
         */
        removeAll: () => {
            Object.keys(state.value).forEach(key => {
                state.value[key] = undefined;
            });
        },
    });
    return state;
};
