import { css } from '@emotion/react';
import { Button, PasswordInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useProxy } from 'valtio/utils';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { ServiceAlternativeSwitch } from '~/pages/strategy_center_admin/_components/ServiceAlternativeSwitch';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export const PageLoginForm = (props) => {
    const me = useProxy(pageStore.me);
    const form = useForm({
        initialValues: {
            account: props.initialValues?.account || '',
            password: props.initialValues?.password || '',
        },
    });
    return (<div css={css `
        width: 360px;
        padding: 36px;
      `}>
      <form onSubmit={form.onSubmit(values => {
            me.requestLogin(values).catch(err => {
                form.setFieldError('password', err?.message);
            });
        })}>
        <h2>{fr_agents.config.title}</h2>
        <TextInput label='帳號' {...form.getInputProps('account')}/>
        <PasswordInput label='密碼' {...form.getInputProps('password')}/>

        {fr_agents.is['kgi@strategy_center_admin'] && (<ServiceAlternativeSwitch apiType='strategy' label='策略中心 API 主機' dataList={[
                { value: 'http://10.102.201.46:8888', label: '10.102.201.46:8888 (內部員工電腦-主)' },
                { value: 'http://10.100.201.46:8888', label: '10.100.201.46:8888 (內部員工電腦-備)' },
                { value: 'http://10.102.203.46:8888', label: '10.102.203.46:8888 (主機本地-主)' },
                { value: 'http://10.100.203.46:8888', label: '10.100.203.46:8888 (主機本地-備)' },
                //{ value: 'http://10.99.25.123:8888', label: '10.99.25.123:8888 (測試機)' },
            ]}/>)}

        {fr_agents.is['kgi@strategy_center_admin'] && (<ServiceAlternativeSwitch apiType='order' label='下單中台 API 主機' dataList={[
                { value: 'http://10.102.52.83:80', label: '10.102.52.83:80 (主機本地-主)' },
                { value: 'http://10.100.52.83:80', label: '10.100.52.83:80 (主機本地-備)' },
                //{ value: 'http://10.99.3.96:80', label: '10.99.3.96:80 (測試機)' },
            ]}/>)}

        <Button type='submit'>登入</Button>
      </form>
    </div>);
};
