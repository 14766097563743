import decodeJwt from 'jwt-decode';
import { isError, set } from 'lodash';
import { proxy } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { apirc } from '~/configs/apirc';
import { createApi } from '~/configs/apirc/OrderPlacementService/createApi';
import { StrategyDevAPI } from '~/configs/apirc/StrategyDevAPI';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import appGetNamespace from '~/modules/SDK/app/appGetNamespace';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__';
import dayAPI from '~/utils/dayAPI';
const hasLocalStorage = __IS_CLIENT__;
const STRATEGY_SERVICE_URL_STORAGE_KEY = appGetNamespace('strategyServiceUrl');
const ORDER_SERVICE_URL_STORAGE_KEY = appGetNamespace('orderServiceUrl');
const meStorage = {
    get: () => !hasLocalStorage
        ? null
        : JSON.parse(localStorage.getItem(appGetNamespace(`me`)) || 'null'),
    set: (value) => localStorage.setItem(appGetNamespace(`me`), value ? JSON.stringify(value) : 'null'),
};
export const strategyServiceURLs = {
    get: () => !hasLocalStorage
        ? null
        : JSON.parse(localStorage.getItem(STRATEGY_SERVICE_URL_STORAGE_KEY) || 'null'),
    set: (value) => localStorage.setItem(STRATEGY_SERVICE_URL_STORAGE_KEY, value ? JSON.stringify(value) : 'null'),
};
export const orderServiceURLs = {
    get: () => !hasLocalStorage
        ? null
        : JSON.parse(localStorage.getItem(ORDER_SERVICE_URL_STORAGE_KEY) || 'null'),
    set: (value) => localStorage.setItem(ORDER_SERVICE_URL_STORAGE_KEY, value ? JSON.stringify(value) : 'null'),
};
if (fr_agents.is['kgi@strategy_center_admin']) {
    apirc.strategy = new StrategyDevAPI({
        baseUrl: strategyServiceURLs.get() || `http://__NOT_SET_DOMAIN__`,
        middlewares: StrategyDevAPI.middlewares,
    });
    apirc.orderPlacementService = (() => {
        return createApi({ baseUrl: orderServiceURLs.get() || `http://__NOT_SET_DOMAIN__` });
    })();
}
export function createMeStore() {
    //
    // private
    const _jwtStore = FrLogic.localStorage({ key: 'jwt', initialState: '' });
    const _decodeJwtStore = () => {
        if (_jwtStore.value) {
            return decodeJwt(_jwtStore.value);
        }
        return {};
    };
    //
    // methods
    const methods = {
        loadInfoMe: async function loadInfoMe() {
            const account = store.current?.account || _decodeJwtStore()?.sub || '__ACCOUNT_NOT_SET__';
            return await apirc.strategy
                .http('get', '/api/users/{account}')
                .request({ account })
                .then(remoteMeInfo => {
                store.current = remoteMeInfo;
            })
                .catch(error => {
                const hasError = isError(error);
                const hasFailedFetch = error.message.toLowerCase() === 'Failed to fetch'.toLowerCase();
                if (hasError && !hasFailedFetch) {
                    store.logout();
                }
                return Promise.reject(error);
            });
        },
        requestLogin: async function requestLogin(params) {
            return await apirc.strategy
                .http('post', '/api/users/login')
                .request(params)
                .then(response => {
                if (!response.accessToken) {
                    throw new Error(`找不到 response.accessToken`);
                }
                _jwtStore.setValue(response.accessToken);
            })
                .then(store.loadInfoMe);
        },
        logout: function logout() {
            _jwtStore.removeValue();
            store.current = null;
            setTimeout(() => {
                location.reload();
            }, 500);
        },
        setRole(role) {
            set(store, 'current.role', role);
        },
        /** 根據 `jwt.exp` 來決定是否保留前端 me 登入狀態 */
        refreshMeLoginState: () => {
            const jwtExp = _decodeJwtStore()?.exp;
            if (!jwtExp) {
                return;
            }
            const exp = dayAPI(jwtExp * 1000);
            const now = dayAPI();
            const isExpired = exp.isBefore(now);
            console.info('refreshMeLoginState', {
                jwtExp,
                exp: exp.format(),
                now: now.format(),
                isExpired,
            });
            if (isExpired) {
                store.logout();
            }
        },
    };
    //
    // store
    const store = proxy({
        current: null,
        strategyServiceUrl: strategyServiceURLs.get(),
        orderServiceUrl: orderServiceURLs.get(),
        ...methods,
        get jwt() {
            return _jwtStore.value;
        },
        get hasLogin() {
            const hasJwt = !!_jwtStore.value;
            const hasMe = !!store.current;
            return hasJwt && hasMe;
        },
    });
    //
    // store initialization
    if (_jwtStore.value) {
        store.loadInfoMe();
    }
    subscribeKey(store, 'current', () => {
        meStorage.set(store.current);
    });
    subscribeKey(store, 'strategyServiceUrl', () => {
        strategyServiceURLs.set(store.strategyServiceUrl);
        apirc.strategy = new StrategyDevAPI({
            baseUrl: store.strategyServiceUrl || `http://__NOT_SET_DOMAIN__`,
            middlewares: StrategyDevAPI.middlewares,
        });
    });
    subscribeKey(store, 'orderServiceUrl', () => {
        orderServiceURLs.set(store.orderServiceUrl);
        apirc.orderPlacementService = createApi({
            baseUrl: orderServiceURLs.get() || `http://__NOT_SET_DOMAIN__`,
        });
    });
    return store;
}
