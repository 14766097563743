import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import { dayjs } from '@futures.tw/dayjs';
export function createAuditLogStore() {
    const initialFilterValues = {
        dateGte: dayjs().subtract(3, 'months'),
        dateLte: dayjs(),
        tableName: '',
        action: undefined,
    };
    const store = proxy({
        //
        // state
        data: [],
        sortBy: FrLogic.sortBy(['datetime']),
        pagination: FrLogic.pagination(),
        filter: { ...initialFilterValues },
        async loadData() {
            const res = await apirc.strategy.http('get', '/api/auditLogs').request({
                'datetime[gte]': store.filter.dateGte.startOf('day').format(),
                'datetime[lte]': store.filter.dateLte.endOf('day').format(),
                ...store.sortBy.httpParams,
                ...store.pagination.getHttpParams(),
                action: store.filter.action,
                tableName: store.filter.tableName,
            });
            store.data = res.data || [];
            store.pagination.setPagination(res);
        },
    });
    return store;
}
