import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
export function createMonitorStore() {
    const actions = {
        async loadConnections() {
            store.connections = await apirc.strategy.http('get', '/api/signalr/connections').request({});
        },
    };
    const state = {
        connections: [],
    };
    const store = proxy({
        ...state,
        ...actions,
    });
    return store;
}
