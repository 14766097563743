import { isNaN } from 'lodash';
import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';
import { apirc } from '~/configs/apirc';
import { useQueryParam } from '~/hooks/useQueryParam';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2';
export function createOrderPanelStore() {
    const initialFilterValues = {
        tab: new FuiTabs2(['未平倉', '成交明細', '委託訂單']),
        tabOfOrders: new FuiTabs2(['Orders', 'PendingOrders']),
        filter: {
            id: 0,
        },
        /** 當前 UI 介面用於呈現的 state */
        current: {
            module: 'futures',
            strategy: null,
            usePrepareStrategyByUrl: () => {
                const strategyId = Number(useQueryParam('strategyId'));
                const strategy = useSnapshot(store.current).strategy;
                const strategies = useSnapshot(store).strategies;
                useEffect(() => {
                    if (strategies.length &&
                        strategyId !== store.current.strategy?.id &&
                        !isNaN(strategyId)) {
                        store.current.setStrategyById(strategyId);
                        store.loadData();
                    }
                }, [strategies, strategyId]);
                return strategy;
            },
            setStrategyById(strategyId) {
                const strategy = store.strategies.find(s => s.id === strategyId);
                store.current.strategy = strategy ? strategy : null;
                window.history.pushState({}, '', `?strategyId=${strategyId}`);
            },
        },
        strategies: [],
        transactions: [],
        transactionsPagination: FrLogic.pagination({ page: 1, pageSize: 100 }),
        transactionsSorter: FrLogic.sortBy([
            'datetime',
        ]),
        orders: [],
        ordersPagination: FrLogic.pagination({ page: 1, pageSize: 100 }),
        ordersSorter: FrLogic.sortBy([
            'createdDatetime',
        ]),
        positions: [],
        positionsPagination: FrLogic.pagination({ page: 1, pageSize: 100 }),
        positionsSorter: FrLogic.sortBy([
            'datetime',
        ]),
    };
    const store = proxy({
        ...initialFilterValues,
        async loadStrategiesFromCurrent() {
            /** 不帶參數，預設會回傳此帳號擁有權限的所有策略 */
            const strategies = await apirc.strategy.http('get', '/api/strategies').request({});
            store.strategies = strategies;
            if (!store.current.strategy) {
                store.current.strategy = strategies[0] || null;
            }
        },
        async closePosition() {
            alert(`平倉 FIXME`);
        },
        async loadData() {
            const id = store.current.strategy?.id || 0;
            const transactionsRes = await apirc.strategy
                .http('get', '/api/strategies/{strategyId}/transactions')
                .request({
                strategyId: id,
                ...store.transactionsPagination.getHttpParams(),
                ...store.transactionsSorter.httpParams,
            });
            store.transactionsPagination.setPagination(transactionsRes);
            const ordersRes = await apirc.strategy
                .http('get', '/api/strategies/{strategyId}/orders')
                .request({
                strategyId: id,
                ...store.ordersPagination.getHttpParams(),
                ...store.ordersSorter.httpParams,
                status: store.tabOfOrders.store.active === 'Orders' ? undefined : 'Pending',
            });
            store.ordersPagination.setPagination(ordersRes);
            const positionsRes = await apirc.strategy
                .http('get', '/api/strategies/{strategyId}/detailedPositions')
                .request({
                strategyId: id,
                ...store.positionsPagination.getHttpParams(),
                ...store.positionsSorter.httpParams,
            });
            //fixed: typing error
            store.positionsPagination.setPagination(positionsRes);
            store.transactions = transactionsRes.data || [];
            store.orders = ordersRes.data || [];
            store.positions = positionsRes.data || [];
        },
    });
    devtools(store, { name: 'OrderPanelStore' });
    return store;
}
