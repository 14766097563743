import { useRouter } from 'next/router';
import isString from 'lodash/isString';
/**
 * ## Note: You actually don't know the string that from client (browser) is given to you at runtime
 *
 * @example
 *   const OtherNextPage = memo(function OtherNextPage() {
 *     return <AppLink href='/moneycom/play/video?v=wdZwXaDnXg4'>A Link</AppLink>
 *   })
 *
 *   const PlayVideoNextPage = memo(function PlayVideoNextPage(props) {
 *     const videoId: string | undefined = useQueryParam('v')
 *
 *     return <span>videoId = {videoId ? videoId : '找不到'}</span>
 *   })
 *
 * @example
 *   const OtherNextPage = memo(function OtherNextPage() {
 *     return <AppLink href='/moneycom/play/video?v=wdZwXaDnXg4&v=DnXg4wdZwXa'>A Link</AppLink>
 *   })
 *
 *   const PlayVideoNextPage = memo(function PlayVideoNextPage(props) {
 *     const videoId: string[] | undefined = useQueryParam(['v'])
 *
 *     return (
 *       <span>
 *         videoId = {videoId?.[0]}, {videoId?.[1]}
 *       </span>
 *     )
 *   })
 */
export const useQueryParam = (getQueryName) => {
    const queryName = Array.isArray(getQueryName)
        ? getQueryName[0]
        : isString(getQueryName)
            ? getQueryName
            : '__UNKNOWN_TYPE_';
    const param = useRouter().query[queryName];
    return param;
};
