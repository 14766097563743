import { proxy } from 'valtio';
export const _logicOneOfStrings = (options) => {
    const template = proxy({
        options: options,
        active: options[0],
        setActive: (option) => {
            template.active = option;
        },
    });
    return template;
};
/* istanbul ignore next */
function TypingTesting() {
    const valtioStore = {
        filters: {
            oneOfKind: _logicOneOfStrings(['全選', '台指期', '小那斯達克']),
        },
    };
    valtioStore.filters.oneOfKind.active === '全選';
    valtioStore.filters.oneOfKind.setActive('台指期');
    valtioStore.filters.oneOfKind.active === '台指期';
    // @ts-expect-error 唯讀，如果擅改會報紅線
    valtioStore.filters.oneOfKind.options = 'TypeError readonly';
    // @ts-expect-error 唯讀，如果擅改會報紅線
    valtioStore.filters.oneOfKind.active = 'TypeError readonly';
}
