import { mapKeys } from 'lodash';
import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
export function createInstrumentStore() {
    const state = {
        instruments: [],
        instrumentsMapp: {},
    };
    const methods = {
        getDisplayName(symbolRoot) {
            return store.instrumentsMapp[symbolRoot || '']?.displayName || symbolRoot || '';
        },
        async loadInstruments() {
            const instruments = await apirc.strategy.http('get', '/api/instruments').request({});
            store.instruments = instruments;
            store.instrumentsMapp = mapKeys(instruments, item => item.symbolRoot);
        },
    };
    const store = proxy({
        ...state,
        ...methods,
    });
    return store;
}
