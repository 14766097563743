import { css } from '@emotion/react';
import { Fragment } from 'react';
import { useProxy } from 'valtio/utils';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
/** 誰可以看到組件？ */
export function Permission(props) {
    const currentRole = useProxy(pageStore).me.current?.role;
    if (!currentRole || !props.roles.includes(currentRole)) {
        return (<div data-rule={'hidden'} data-permission={currentRole} css={css `
          display: contents;
        `}/>);
    }
    if (props.roles.includes(currentRole)) {
        return <Fragment>{props.children}</Fragment>;
    }
}
