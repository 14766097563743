import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
export function createStrategistStore() {
    const actions = {
        async loadStrategists() {
            const users = await apirc.strategy.http('get', '/api/users').request({});
            store.strategists = users;
        },
    };
    const store = proxy({
        strategists: [],
        ...actions,
    });
    return store;
}
